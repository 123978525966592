<template lang="pug">
div
  Form(:data="worker", :isSubmitting="isSubmitting", :isPCUploadVisible="false", @submit="update")
</template>

<script>
import { onMounted } from '@vue/composition-api'
import Form from '../shared/Form.vue'
import useWorkerEdit from './useWorkerEdit'

export default {
  components: {
    Form,
  },

  setup() {
    const { update, show, worker, isSubmitting } = useWorkerEdit()

    onMounted(show)

    return {
      worker,
      update,
      isSubmitting,
    }
  },
}
</script>
