import useNotifications from '@/composables/useNotifications'
import { useRouter } from '@core/utils/utils'
import useJwt from '@/auth/jwt/useJwt'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useWorkerEdit() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { route, router } = useRouter()
  const { getItem, updateItem, ObjectId } = realmConnection()
  const worker = ref({})
  const isSubmitting = ref(false)
  const workerObjectId = ObjectId(route.value.params.id)
  const query = { _id: workerObjectId }
  const collection = 'worker'

  const show = async () => {
    isSubmitting.value = true

    try {
      const item = await getItem({ collection, query })
      if (!item) throw new Error('Item not found')

      worker.value = {
        ...item,
        supervisors: item.supervisors?.map((e) => e.toString()) || [],
        locations: item.locations?.map((e) => e.toString()) || [],
        roles: item.roles?.map((e) => e.toString()) || [],
      }
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.worker_fetch_error'))
    } finally {
      isSubmitting.value = false
    }
  }

  const update = async (data) => {
    isSubmitting.value = true

    try {
      const payload = {
        name: data.name?.value || '',
        email: data.email?.value || '',
        supervisors: data.supervisors?.value?.map(e => ObjectId(e)) || [],
        locations: data.locations?.value?.map(e => ObjectId(e)) || [],
        roles: data.roles?.value?.map(e => ObjectId(e)) || [],
        privileges: data.privileges?.value || '',
      }

      const defaultPassword = '12345678'
      const action = { $set: payload }

      await updateItem({ collection, query, action })

      // Check if worker is an existing user and update it.
      // If not and role is consultant or supervisor, register as new user.
      const userQuery = { 'custom_data.worker_id': workerObjectId }
      const item = await getItem({ collection: 'user', query: userQuery })
      const userPayload = {
        "custom_data.username": payload.name,
        "custom_data.role": payload.privileges,
        "custom_data.worker_id": workerObjectId,
        "custom_data.ability": [ { action: 'manage', subject: 'all' } ],
      }
      const userAction = { $set: userPayload }

      if (item) {
        await updateItem({ collection: 'user', query: userQuery, action: { $set: { deleted: payload.privileges === 'employee' } } })
        if (payload.privileges !== 'employee') {
          await updateItem({ collection: 'user', query: { id: item.id }, action: userAction })
        }
      } else if (payload.privileges === 'consultant' || payload.privileges === 'supervisor') {
        await useJwt.register({ email: payload.email, password: defaultPassword, name: payload.name })
        const logUser = await useJwt.login({ email: payload.email, password: defaultPassword })
        await updateItem({ collection: 'user', query: { id: logUser.id }, action: userAction })
        await useJwt.realm.allUsers[logUser.id].logOut()
      }

      showSuccessMessage(i18n.t('message.worker_updated'))
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.worker_update_error'))
    } finally {
      router.push({ name: 'organization-worker-list' })
      isSubmitting.value = false
    }
  }

  return {
    update,
    show,
    worker,
    isSubmitting,
  }
}
